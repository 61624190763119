import {useEffect, useState} from 'react';
import {
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid, LinearProgress,
  Typography
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import withStore from '../../globalStore/withStore';

import StepsHeader from '../../components/StepsHeader';
import styles from './styles';
import TagCostVRN from '../../components/TagCostVRN';
import AutocompleteInput from '../../UIKit/AutocompleteInput';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Spacer from '../../UIKit/Spacer';
import ButtonGroupInput from '../../UIKit/RadioGroupInput';
import Button from '../../UIKit/Button';
import FileInput from '../../UIKit/FileInput';
import {saveVehicleDetails, tagCost, uploadRC, vehicleMake, vehicleModel} from '../../api';
import commonStyles from '../../UIKit/commonStyles';

const initialValues = {
  make: {},
  makeKey: '',
  makeLabel: '',
  model: {},
  modelKey: '',
  modelLabel: '',
  vehicleCategory: 'private',
  uploadRC: '',
};

const vehicleCategoryOptions = [
  {
    value: 'private',
    label: 'Private'
  },
  {
    value: 'commercial',
    label: 'Commercial / Taxi'
  }
];

const VehicleTagCost = ({getStore, updateStore}) => {
  const storeData = getStore();
  const [loading, setLoading] = useState(false);
  const [loadingVehicleModel, setLoadingVehicleModel] = useState(false);
  const [vehicleMakeList, setVehicleMakeList] = useState([]);
  const [vehicleModelList, setVehicleModelList] = useState([]);
  const [tagTotalAmount, setTagTotalAmount] = useState(null);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    make: Yup.object().nullable(),
    model: Yup.object().nullable(),
    makeKey: Yup.string().nullable().required('Mandatory field!'),
    makeLabel: Yup.string().nullable().required('Mandatory field!'),
    modelKey: Yup.string().nullable().required('Mandatory field!'),
    modelLabel: Yup.string().nullable().required('Mandatory field!'),
    vehicleCategory: Yup.string().nullable().required('Mandatory field!'),
    uploadRC: Yup.string().nullable().required('Mandatory field!')
  });

  useEffect(() => {
    getTagCost({vehicleClass: '4'});
    getVehicleMake()

    const {vrnDetails} = storeData;
    if(vrnDetails?.vehicleMake){
      const {vehicleMake, vehicleModel} = vrnDetails;
      setFieldValue('make', {
        label: vehicleMake.MakeDescription,
        key: vehicleMake,
      })
      setFieldValue('makeLabel', vehicleMake.MakeDescription)
      setFieldValue('makeKey', vehicleMake?.MakeCode)

      setFieldValue('model', {
        label: vehicleModel?.ModelDescription,
        key: vehicleModel,
      })
      setFieldValue('modelLabel', vehicleModel?.ModelDescription)
      setFieldValue('modelKey', vehicleModel?.Model)
    }
    if(vrnDetails?.rcVerified){
      setFieldValue('uploadRC', 'verified')
    }
  }, [])

  const getTagCost = async () => {
    try{
      const {GSTAmount, TagIssuanceFee, TagSecurityDeposit, TagTotalAmount, TagUploadAmount} = await tagCost();
      updateStore({
        tagCost: {
          gstAmount: GSTAmount,
          tagIssuanceFee: TagIssuanceFee,
          tagSecurityDeposit: TagSecurityDeposit,
          tagTotalAmount: TagTotalAmount,
          tagUploadAmount: TagUploadAmount,
        }
      })
      setTagTotalAmount(TagTotalAmount)
    }catch (err){
      console.log(err);
    }
  }

  const getVehicleModels = async (value) => {
    if(!value) return;
    try{
      setLoadingVehicleModel(true);
      const {VehicleModels} = await vehicleModel({vehicleMake: value.key.MakeCode});
      setLoadingVehicleModel(false);
      setVehicleModelList(VehicleModels.map((item) => ({label: item.ModelDescription, key: item})))
    }catch (err){
      setLoadingVehicleModel(false);
    }
  }

  const getVehicleMake = async () => {
    try{
      const response = await vehicleMake();
      setVehicleMakeList(response.map((item) => ({label: item.MakeDescription, key: item})))
    }catch (err){
      console.log(err);
    }
  }

  const handleVehicleMakeChange = (v) => {
    setFieldValue('make', v);
    setFieldValue('model', {});
    setFieldValue('makeLabel', v?.label ?? null);
    setFieldValue('makeKey', v?.key?.MakeCode ?? null);
    setFieldValue('modelLabel', '');
    setFieldValue('modelKey', '');
    getVehicleModels(v)
  }

  const handleVehicleModelChange = (v) => {
    setFieldValue('model', v);
    setFieldValue('modelLabel', v?.label ?? null);
    setFieldValue('modelKey', v?.key?.Model ?? null);
  }

  const handleFileSelect = async (file) => {
    try{
      const {issuanceParams: {amazon_ref}} = storeData;
      const formData = new FormData();
      formData.append('image', file, `${amazon_ref}_rc_doc.jpg`);
      const {message} = await uploadRC(formData)
      setFieldValue('uploadRC', 'uploaded');
      return {message: message ?? "RC image upload successful!"}
    }catch (err){
      throw new Error('RC image upload failed!');
    }
  }


  const handleSubmit = async (values) => {
    const vehicleDetails = {
      vehicleCategory: values.vehicleCategory,
      vehicleMake: values.make?.key,
      vehicleModel: values.model?.key,
    }
    setLoading(true)
    try{
      await saveVehicleDetails(vehicleDetails);
      updateStore({vehicleDetails})
      setLoading(false)
      navigate('/personal-info', {replace: true})
    }catch (err){
      setLoading(false)
    }
  };

  const {vrn} = storeData?.applicationDetails ?? {};
  const {rcVerified} = storeData?.vrnDetails ?? {};
  const {values, errors, submitCount, setFieldValue, submitForm} = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const formTouched = submitCount > 0;

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={40}/>
      <Grid container direction="row" justifyContent="center">
        <StepsHeader text="What is the type of your vehicle?"/>

        <Card variant="outlined" sx={{width: '100%', marginBottom: '30px'}}>
          <TagCostVRN
            loading={false}
            fastagPrice={tagTotalAmount}
            rcNumber={vrn}
          />

          <CardContent>
            <Grid direction="column" container spacing={3}>
              <Grid item>
                <AutocompleteInput
                  label="Search Vehicle Make"
                  required
                  error={formTouched && errors.makeKey}
                  value={values.make}
                  options={vehicleMakeList}
                  onChange={handleVehicleMakeChange}
                />
                <Spacer space={12}/>
                <AutocompleteInput
                  label="Search Vehicle Model"
                  required
                  disabled={!values?.make?.label}
                  options={vehicleModelList}
                  loading={loadingVehicleModel}
                  error={formTouched && errors.modelKey}
                  value={values.model}
                  onChange={handleVehicleModelChange}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider/>
          <Typography sx={styles.typeVehicle}>
            Select the type of vehicle
          </Typography>
          <CardActions sx={{padding: '8px 16px'}}>
            <ButtonGroupInput
              row
              value={values.vehicleCategory}
              onChange={v => setFieldValue('vehicleCategory', v)}
              options={vehicleCategoryOptions}
            />
          </CardActions>
        </Card>

        {
          !rcVerified && (
            <Grid item style={{marginBottom: 30}}>
              <Typography sx={styles.uploadRCText}>
                RC number could not be verified against our records. Please upload an
                image of your RC document for verification
              </Typography>

              <FileInput
                onFileUpload={handleFileSelect}
                maxSizeInMB={8}
                hasError={formTouched && errors.uploadRC}
                allowedFileTypes={['image/jpg', 'image/jpeg', 'image/png']}
                label="Upload vehicle RC document"
                successText="Copy of RC image uploaded successfully!"
                helperText=" Max size: 8 MB | Only .jpeg, .jpg, .png formats are supported"
              />
            </Grid>
          )
        }

        <Spacer space={24}/>

        <Button loading={loading} onClick={submitForm}>Continue</Button>
      </Grid>
    </Container>
  );
};

export default withStore(VehicleTagCost);