export const stateList = {
  AN: {
    code: 'AN',
    name: 'Andaman & Nicobar',
  },
  AP: {
    code: 'AP',
    name: 'Andhra Pradesh',
  },
  AR: {
    code: 'AR',
    name: 'Arunachal Pradesh',
  },
  AS: {
    code: 'AS',
    name: 'Assam',
  },
  BR: {
    code: 'BR',
    name: 'Bihar',
  },
  CH: {
    code: 'CH',
    name: 'Chandigarh',
  },
  CG: {
    code: 'CG',
    name: 'Chhattisgarh',
  },
  DN: {
    code: 'DN',
    name: 'Dadra and Nagar Haveli',
  },
  DD: {
    code: 'DD',
    name: 'Daman & Diu',
  },
  DL: {
    code: 'DL',
    name: 'Delhi',
  },
  GA: {
    code: 'GA',
    name: 'Goa',
  },
  GJ: {
    code: 'GJ',
    name: 'Gujarat',
  },
  HR: {
    code: 'HR',
    name: 'Haryana',
  },
  HP: {
    code: 'HP',
    name: 'Himachal Pradesh',
  },
  JK: {
    code: 'JK',
    name: 'Jammu & Kashmir',
  },
  JH: {
    code: 'JH',
    name: 'Jharkhand',
  },
  KA: {
    code: 'KA',
    name: 'Karnataka',
  },
  KL: {
    code: 'KL',
    name: 'Kerala',
  },
  LD: {
    code: 'LD',
    name: 'Lakshadweep',
  },
  MP: {
    code: 'MP',
    name: 'Madhya Pradesh',
  },
  MH: {
    code: 'MH',
    name: 'Maharashtra',
  },
  MN: {
    code: 'MN',
    name: 'Manipur',
  },
  ML: {
    code: 'ML',
    name: 'Meghalaya',
  },
  MZ: {
    code: 'MZ',
    name: 'Mizoram',
  },
  NL: {
    code: 'NL',
    name: 'Nagaland',
  },
  OR: {
    code: 'OR',
    name: 'Odisha',
  },
  OD: {
    code: 'OR',
    name: 'Odisha',
  },
  PY: {
    code: 'PY',
    name: 'Pondicherry',
  },
  PB: {
    code: 'PB',
    name: 'Punjab',
  },
  RJ: {
    code: 'RJ',
    name: 'Rajasthan',
  },
  SK: {
    code: 'SK',
    name: 'Sikkim',
  },
  TN: {
    code: 'TN',
    name: 'Tamil Nadu',
  },
  TS: {
    code: 'TS',
    name: 'Telangana',
  },
  TR: {
    code: 'TR',
    name: 'Tripura',
  },
  UP: {
    code: 'UP',
    name: 'Uttar Pradesh',
  },
  UK: {
    code: 'UK',
    name: 'Uttarakhand',
  },
  WB: {
    code: 'WB',
    name: 'West Bengal',
  },
};

const stateCodeList = Object.keys(stateList);

export const getStateDataByCode = (key) => {
  const searchKey = key?.toUpperCase() ?? '';
  try {
    return stateList[searchKey];
  } catch (err) {
    return {};
  }
};

export const getStateDataByName = (name) => {
  const searchName = name?.toLowerCase() ?? '';
  for (let i = 0; i < stateCodeList.length; i += 1) {
    const stateObj = stateList[stateCodeList[i]];
    if (stateObj.name.toLowerCase() === searchName) {
      return stateObj;
    }
  }
  return {};
};

export const getStateList = () => {
  return Array.from(new Set(stateCodeList.map((key) => stateList[key].name)));
};

export const getStateListOptions = () => {
  return Array.from(new Set(stateCodeList.map((key) => ({
    label: stateList[key].name,
    value: stateList[key].code,
  }))));
};

export const isValidStateCode = (code) => {
  const searchCode = code?.toUpperCase() ?? '';
  return stateCodeList.indexOf(searchCode) >= 0;
};
