import {useState} from 'react';
import {Container, Grid, Box, Typography, LinearProgress, Alert} from '@mui/material';
import OtpInput from 'react-otp-input';
import {useNavigate} from 'react-router-dom';
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';

import withStore from '../../globalStore/withStore';
import Button from '../../UIKit/Button';
import StepsHeader from '../../components/StepsHeader';

import styles from './styles';
import commonStyles from '../../UIKit/commonStyles';
import useCountdown from '../../hooks/useCountdown';
import {secondsToHHMMSS} from '../../utils/time';
import {sendOTP, verifyOTP} from '../../api';
import {setStorageItem} from '../../utils/storage';

const MAX_ATTEMPTS = 5;

const VerifyOTP = ({getStore, updateStore}) => {
  const navigate = useNavigate();
  const storeData = getStore();
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [otp, setOTP] = useState('');
  const [error, setError] = useState('');
  const [resendOTPCount, setResendOTPCount] = useState(0);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingResendOTP, setLoadingResendOTP] = useState(false);
  const {countdown} = useCountdown({time: '00:02:00', reset: resendOTPCount})

  const handleOTPInput = (v) => {
    setError('');
    setOTP(v)
  }

  const handleReCaptchaVerify = () => {
    if (!executeRecaptcha) return;
    return executeRecaptcha('verifyOTPIssuance' + String.fromCharCode(0|Math.random()*26+97));
  };

  const resendOTP = async () => {
    if(loadingResendOTP) return;

    try {
      setLoadingResendOTP(true);
      const {issuanceParams : {amazon_ref}, applicationDetails: {mobile} } = storeData;
      const captcha = await handleReCaptchaVerify();
      await sendOTP({
        mobile_no: mobile,
        token: captcha,
        appRefNo: amazon_ref
      })
      setResendOTPCount((c) => c + 1);
      setLoadingResendOTP(false);
    }catch (err){
      setLoadingResendOTP(false);
    }
  }

  const handleSubmit = async () => {
    if(!otp || otp?.length !== 6) return;
    const {applicationDetails, issuanceParams : {amazon_ref, utm_source} } = storeData;

    if(retryAttempts >= MAX_ATTEMPTS){
      navigate('/failure', { replace: true, state: {sourceApp: utm_source, errorMessage: 'Maximum retry OTP attempts'}})
      return;
    }

    try{
      setRetryAttempts((attempt) => attempt + 1);
      setLoading(true);
      const {idType, idNumber, pan, vrn, mobile,} = applicationDetails;
      const captcha = await handleReCaptchaVerify();
      const {token, personalDetails, vrnDetails, suggestedAddress} = await verifyOTP({
        otp,
        vrn,
        pan,
        mobile,
        idType,
        idNumber,
        token: captcha,
        sourceApp: utm_source,
        amazonRef: amazon_ref,
      })
      setStorageItem('token', token);

      updateStore({vrnDetails})

      if(personalDetails){
        const {firstName, lastName} = personalDetails;
        updateStore({personalDetails: {firstName, lastName}})
      }

      if(suggestedAddress){
        const {addressLine: addressLine1, pincode} = suggestedAddress;
        updateStore({addressDetails: {addressLine1, pincode}})
      }
      setLoading(false)
      navigate('/vehicle-tag-cost', {replace: true})
    }catch (err){
      setLoading(false)
      let newApplicationError = err?.message ?? 'Something went wrong. Please try again!'
      switch (err?.type){
        case 'VALIDATION':
        case 'INVALID_OTP':
        case 'INVALID_PAN':
        case 'INVALID_VRN':
        case 'HAS_ICICI_FASTAG':
        case 'TAG_BLACKLISTED':
        case 'APPLICATION_EXISTS':
          setError(err.message);
          return navigate('/new-application', {state: {newApplicationError}, replace: true})
        default:
          return navigate('/failure', {
            state: {errorMessage: newApplicationError, sourceApp: utm_source.toLowerCase()},
            replace: true
          })
      }
    }
  }

  return (
    <Container maxWidth="xs" sx={styles.root}>
      <LinearProgress sx={commonStyles.progressBar} variant="determinate" color="primary" value={20}/>
      <Grid container direction="row" justifyContent="center">
        <StepsHeader text="Please enter OTP to verify your mobile number"/>

        <Grid item xs={12} sx={styles.mt8}>
          <OtpInput
            value={otp}
            onChange={handleOTPInput}
            numInputs={6}
            inputType="number"
            renderSeparator={<span>&nbsp;&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
            focusStyle={styles.otpFocus}
            inputStyle={styles.otpInput}
            shouldAutoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container sx={styles.mt8} alignItems="center">
            <Grid item xs={6}>
              {
                countdown <= 0 ? (
                  <Typography>OTP has expired</Typography>
                ) : (
                  <Typography> Time left : {secondsToHHMMSS(countdown, true)}</Typography>
                )
              }
            </Grid>
            <Grid item xs={6} sx={commonStyles.textRight}>
              {
                countdown <= 0 && (
                  <Box onClick={resendOTP} sx={styles.resendBtn}>
                    {
                      loadingResendOTP ? (
                        <Typography>Please wait...</Typography>
                      ) : (
                        <Typography sx={styles.otpResendText}>Resend OTP</Typography>
                      )
                    }
                  </Box>
                )
              }
            </Grid>
          </Grid>
        </Grid>
        {
          !!error && (
            <Grid item xs={12}>
              <Alert variant="filled" sx={{margin: '8px 0'}} severity="error">{error}</Alert>
            </Grid>
          )
        }

        {
          retryAttempts >= 2 && (
            <Grid item xs={12} >
              <Alert sx={{margin: '8px 0'}} severity="warning">{MAX_ATTEMPTS - retryAttempts} attempts left!</Alert>
            </Grid>
          )
        }

        <Grid item xs={12} sx={styles.mt30}>
          <Button loading={loading} disabled={otp?.length !== 6} onClick={handleSubmit}>
            Verify OTP
          </Button>
        </Grid>
      </Grid>
    </Container>
  )
}

export default withStore(VerifyOTP)