import {Route, Routes} from 'react-router-dom';
import NewApplication from './pages/NewApplication';
import Layout from './pages/Layout';
import VerifyOTP from './pages/VerifyOTP';
import VehicleTagCost from './pages/VehicleTagCost';
import PersonalInfo from './pages/PersonalInfo';
import DeliveryAddressInfo from './pages/DeliveryAddressInfo';
import ConfirmOrder from './pages/ConfirmOrder';
import OrderSummary from './pages/OrderSummary';
import FailurePage from './pages/Failure';
import Landing from './pages/Landing';
import ApplicationFailed from './pages/ApplicationFailed';

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout/>}>
        <Route path="/" element={<Landing/>} />
        <Route path="/form/document_info" element={<Landing/>} />
        <Route path="/new-application" element={<NewApplication />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/vehicle-tag-cost" element={<VehicleTagCost />} />
        <Route path="/personal-info" element={<PersonalInfo />} />
        <Route path="/delivery-address-info" element={<DeliveryAddressInfo />} />
        <Route path="/confirm-order" element={<ConfirmOrder />} />
        <Route path="/order-summary" element={<OrderSummary />} />
        <Route path="/application-failed" element={<ApplicationFailed />} />
        <Route path="/failure" element={<FailurePage />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes;