import {useRef, useState} from 'react';
import imageCompression from 'browser-image-compression';
import {Alert, Box, Button, CircularProgress, Grid, IconButton, Typography} from '@mui/material';
import iconDelete from './delete.svg';

const UPLOAD_STATE = {
  INITIAL: 'INITIAL',
  UPLOADING: 'UPLOADING',
  COMPLETE: 'COMPLETE',
}

const FileInput = ({label, hasError, onFileUpload, maxSizeInMB, allowedFileTypes, helperText, successText}) => {
  const fileInputRef = useRef(null);
  const [uploadStatus, setUploadStatus] = useState(UPLOAD_STATE.INITIAL)
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleDelete = (ev) => {
    ev.stopPropagation();
    fileInputRef.current.value = '';
    setUploadStatus(UPLOAD_STATE.INITIAL)
  }

  const onFileSelect = async (ev) => {
    const file = ev.target.files[0];
    if (!file) return;

    const compressedFile = await imageCompression(file, {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    });

    const fileSizeMB = compressedFile.size / 1024 / 1024;

    if(fileSizeMB > maxSizeInMB){
      setError(`File size exceeds! Please select a file with less than ${maxSizeInMB}MB`)
      return;
    }

    if(!allowedFileTypes.includes(compressedFile.type)){
      setError(`Incorrect file type ! Please select a file with type ${allowedFileTypes.join(', ')}`)
      return;
    }

    setError(null);
    try{
      setUploadStatus(UPLOAD_STATE.UPLOADING)
      const {message} = await onFileUpload(compressedFile)
      setSuccess(message ?? successText)
      setUploadStatus(UPLOAD_STATE.COMPLETE)
    }catch (e){
      setError(e.message)
      fileInputRef.current.value = '';
      setUploadStatus(UPLOAD_STATE.INITIAL)
    }
  }

  return (
    <>
      <input hidden ref={fileInputRef} type="file" onChange={onFileSelect}/>

      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item flexGrow={1}>
          <Button
            fullWidth
            onClick={() => uploadStatus === UPLOAD_STATE.INITIAL && fileInputRef.current.click()}
            variant="outlined"
            sx={hasError ? styles.uploadBtnErr : (
              uploadStatus === UPLOAD_STATE.COMPLETE ? styles.uploadBtnSuccess : styles.uploadBtn
            )}
          >
            {
              uploadStatus === UPLOAD_STATE.UPLOADING && (
                <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                  <CircularProgress size={20} sx={styles.blueColor}/>
                  <Typography sx={[styles.blueColor, {marginLeft: '4px'}]}>Uploading file...</Typography>
                </Box>
              )
            }

            {
              uploadStatus === UPLOAD_STATE.COMPLETE && (
                <Typography sx={styles.greenColor}>{success}</Typography>
              )
            }

            {
              uploadStatus === UPLOAD_STATE.INITIAL && (
                <Typography sx={hasError ? styles.redColor : styles.blueColor}>{label}</Typography>
              )
            }
          </Button>
        </Grid>

        {
          uploadStatus === UPLOAD_STATE.COMPLETE && (
            <Grid sx={{padding: "4px"}}>
              <IconButton onClick={handleDelete}>
                <img src={iconDelete} alt="delete"/>
              </IconButton>
            </Grid>
          )
        }
      </Grid>

      <Grid item xs={12} sx={{textAlign: 'center'}}>
        <Typography>{helperText}</Typography>
      </Grid>

      {
        !!error && (<Alert variant="filled" sx={{margin: '8px 0'}} severity="error">{error}</Alert>)
      }

    </>
  )
}

const styles = {
  uploadBtn: (t) => ({
    height: '48px',
    color:  t.palette.blue,
    borderColor: t.palette.blue
  }),
  uploadBtnErr: (t) => ({
    height: '48px',
    color:  t.palette.red,
    borderColor: t.palette.red
  }),
  uploadBtnSuccess: (t) => ({
    height: '48px',
    color:  t.palette.green,
    borderColor: t.palette.green
  }),
  blueColor: (t) => ({color: t.palette.blue}),
  greenColor: (t) => ({color: t.palette.green}),
  redColor: (t) => ({color: t.palette.red}),
}

export default FileInput