import {useState} from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import styles from '../styles';
import iconPerson from '../images/person.svg';
import iconEdit from '../images/edit.svg';
import iconExpandLess from '../images/expand-less.svg';
import iconExpandMore from '../images/expand-more.svg';

const PersonalDetails = ({personalData = {}, mobileNumber}) => {
  const [expandPersonalDetails, setExpandPersonalDetails] = useState(false);
  const navigate = useNavigate()

  const goToPersonalInfoForm = () => {
    navigate('/personal-info', {replace: true})
  }
  const {title, firstName, lastName, gender, dateOfBirth, emailID, motherMaidenName}= personalData;
  return (
    <Accordion sx={styles.accordian} expanded={expandPersonalDetails} onChange={() => setExpandPersonalDetails((f) => !f)}>
      <AccordionSummary sx={styles.accordianSummary}>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <img src={iconPerson} alt="person" />
          </Grid>
          <Grid item xs={8}>
            <Typography>Personal details</Typography>
          </Grid>
          <Grid item xs={1}>
            <img onClick={goToPersonalInfoForm} src={iconEdit} alt="edit" />
          </Grid>
          <Grid style={{ textAlign: 'center' }} item xs={1}>
            {
              expandPersonalDetails ? (
                <img src={iconExpandLess} alt="expand more" />
              ) : (
                <img src={iconExpandMore} alt="expand more" />
              )
            }
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordianDetails}>
        <Grid container>
          <Grid item xs={12}>
            <Typography sx={styles.vehicleMakeTitle}>Full name</Typography>
            <Typography sx={styles.vehicleMakeData}>
              {title} {firstName} {lastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Typography sx={styles.vehicleMakeTitle}>Gender</Typography>
            <Typography sx={styles.vehicleMakeData}>
              {gender}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Typography sx={styles.vehicleMakeTitle}>Date of Birth</Typography>
            <Typography sx={styles.vehicleMakeData}>
              {dateOfBirth}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Typography sx={styles.vehicleMakeTitle}>
              Mother's maiden name
            </Typography>
            <Typography sx={styles.vehicleMakeData}>
              {motherMaidenName}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Typography sx={styles.vehicleMakeTitle}>Mobile number</Typography>
            <Typography sx={styles.vehicleMakeData}>
              {mobileNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Typography sx={styles.vehicleMakeTitle}>Email</Typography>
            <Typography sx={styles.vehicleMakeData}>
              {emailID}
            </Typography>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default PersonalDetails